import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Icon from "components/Icon";

import { IconWrapper } from "./Watermark.styled";
import WatermarkProps from "./WatermarkProps";

const Watermark = ({
    icon,
    variant,
    className,
}: WatermarkProps): ReactElement => {
    return (
        <ThemeProvider theme={{ variant: variant }}>
            <IconWrapper className={className}>
                <Icon icon={`${icon}`} />
            </IconWrapper>
        </ThemeProvider>
    );
};

export default React.memo(Watermark);
