import { styled, css } from "styled-components";
import theme from "styled-theming";

import { styleLabelM } from "style/components/Typography";

export const IconWrapper = styled.div`
    ${styleLabelM}
    align-items: center;
    color: var(--icon-decorative-cyan);
    display: flex;
    ${theme("variant", {
        white: css`
            filter: brightness(0) saturate(100%) invert(100%)
                var(--shadow-on-image);
        `,
    })}
    inset: 0;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    z-index: 3;
`;
